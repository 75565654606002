import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import './Sidebar.css'
import { SlDocs } from "react-icons/sl";
import { ImKey } from "react-icons/im";
import { IoClose } from "react-icons/io5";



const Sidebar1 = ({ children }) => {


  useEffect(() => {

    // to set the width of the sidebar and the main content and to check the mobile view and whether the sidebar is open or not
    if (window.screen.width <= 1000) {
      setIsOpen1(false);
      setMobile(true);
      setW(window.screen.width - 55);
    } else {
      setW(window.screen.width - 265);
    }
  }, []);


  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

    // create a function to update the screen size
    const updateScreenSize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      if(screenSize.width <= 1000){
        setMobile(true)
        setIsOpen1(false)
      }else{
        setMobile(false)
        setIsOpen1(true)
      }
    };

      // use an effect hook to add and remove the window resize event listener
  useEffect(() => {
    // add the event listener on mount
    window.addEventListener("resize", updateScreenSize);

    // return a cleanup function to remove the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []); // pass an empty dependency array to run the effect only once


  //states
  const [w,setW] = useState(window.screen.width <= 1000 ? window.screen.width-55 : window.screen.width-265)
  const [isOpen, setIsOpen1] = useState(true);
  const [mobile, setMobile] = useState(false);

  //sidebar toggle
  const toggle = () => {
    setIsOpen1(!isOpen);
  };

  //list of menu items
  const menuItem = [
    {
      path: `/`,
      name: "🚀Getting Started",
      icon: <SlDocs />,
    },
    {
      path: `/api-overview`,
      name: "📄 API Overview",
      icon: <SlDocs />,
    },
    {
      path: `/api`,
      name: "🔑 REST API",
      icon: <ImKey />,
    },
    {
      path: `/collections`,
      name: "👩‍🦳 Collections",
      icon: <SlDocs />,
    },

  ];


  return (
    <div className="container-sidebar position-relative">
      <div
        style={{ width: isOpen ? "265px" : "55px", zIndex: 500,borderRight:"1px solid #444950" }}
        className="sidebar"

      >
        <div className="top_section d-flex align-items-center justify-content-center" style={{position : isOpen ? "relative" : "absolute"}}>
          <img
            src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/xrc-api.png"
            style={{
              display: isOpen ? "block" : "none",
              marginTop:"15px",
              marginBottom:"15px",
              paddingBottom:"10px",
              borderBottom: "1px solid rgb(252 252 252 / 36%)"
              // height: "auto",
            }}
            alt="logo"
            className="logo-sidebar"
          />
          <div
            style={{
              marginLeft: isOpen ? "30px" : "0px",
              marginRight: isOpen ? "20px" : "0px",
              position : isOpen ? "absolute" : "relative",
              top : isOpen ? "10px" : "0px",
              right : isOpen ? "10px" : "0px",
            }}
            className="bars"
          >
            {isOpen ?  <IoClose size={25} onClick={toggle} /> : <FaBars onClick={toggle} />}
          </div>
        </div>

        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={`link ${item.class}`}
            activeclassName="active"
            style={{ paddingLeft: "20px",textDecoration:"none", display:isOpen?"block":"none"}}
            id={item.id}
            onClick={()=>{mobile && setIsOpen1(false)}}
          >
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}

      </div>
      <main
        style={{
          width: `${w}px`,
          overflowX: "hidden",
          padding:"4em",
          paddingBootom:0,
          paddingTop:'2em'
       
        }}
        className="main-side"
      >
        {children}
      </main>
      
    </div>
  );
};

export default Sidebar1;

// ,marginLeft:isOpen?"250px":"50px"
