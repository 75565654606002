import { useEffect } from "react";
import { useNavigate } from "react-router";

const RouteNotDefined = () => {

  const navigate = useNavigate();
  useEffect(() => {
    navigate('/')
  }, [navigate])
};

export default RouteNotDefined;
