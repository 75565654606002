import React, { useState, useEffect } from "react";
import image1 from "./1.jpg";
import "./GettingStarted.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
} from "reactstrap";
import { MdOutlineNavigateNext } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { useNavigate } from "react-router";

function GettingStarted() {
  //dropdown states
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate()

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [mob, setMob] = useState(false);
  useEffect(() => {
    if (window.screen.width <= 1000) {
      setMob(false);
    } else {
      setMob(true);
    }
  }, []);

  return (
    <div>
      <div className="d-flex main-content-container">
        <main style={{ width: mob ? "100%" : "100%", paddingRight: "4%" }}>


          {/* custom breadcrumb */}
          <div
            className="breadcrumb-overview d-flex flex-row align-items-center"
            style={{ width: "fit-content" }}
          >
            <div className="home-icon">
              <IoMdHome
                style={{color: "#3578e5"  ,cursor: "pointer" }}
                className="link-text"
              />
            </div>
          </div>

          {/* dropdown */}
          {/* <div className="d-flex dropdown-section mt-2 mb-2 w-100">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"} className='w-100'>
              <DropdownToggle className='w-100 d-flex justify-content-between align-items-center' style={{background:"#272829",opacity:1, border:"1px solid #444950"}} caret>On this Page</DropdownToggle>
              <DropdownMenu className='w-100' style={{background:"#272829", color:"white !important",position:"relative"}}>
                <DropdownItem> <a style={{color:"#fbfbfb"}} className='font-body' href='#api-overview'>API Overview</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#goal-and-vision'>Goal & Vision</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#key-features'>Key Features</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#avatar-standard'>Wearable Standards</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#benefits'>Benefits</a></DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div> */}

          {/* overview */}
          <div>
          <p className="text-header" id="api-overview">
          🎉 Welcome
          </p>
          <p className="text-justify font-body" style={{textAlign:"justify"}}>
            XR Couture is a leading digital wearables platform that creates and
            supplies hyper-realistic wearables that can be used on custom
            avatars.
          </p>
          <img src={image1} width="80%" style={{ marginLeft: "10%" }} />
          <p className="text-sub-heading" style={{ fontWeight: "bolder" }}>
            Get access to XR Couture collections in 3 steps.
          </p>
          </div>



          {/* steps */}
          <div className="d-flex row flex-row justify-content-center font-body" style={{padding:"1em"}}>
            <div
              className="div-points col-12 col-md-3 "
              style={{ cursor: "pointer" }}
              onClick={() => navigate('/api-overview')}
            >
              1. OVERVIEW
              <p className="link-text">
                Here you can learn the benefits & how to get access.
              </p>
            </div>
            <div
              className="div-points col-12 col-md-3 "
              style={{ cursor: "pointer" }}
              onClick={() => navigate('/api')}
            >
              2. INTEGRATE
              <p className="link-text">
                Integrate XR Couture API into your platform.
              </p>
            </div>
            <div
              className="div-points col-12 col-md-3 "
              style={{ cursor: "pointer" }}
              onClick={() => navigate('/collections')}
            >
              3. COLLECTIONS
              <p className="link-text">
              Check out our collections of wearables for your avatar. Find what you like and need.
              </p>
            </div>
          </div>

          {/* naivation */}
          <div
            className="static-navigation d-flex flex-row m-0 justify-content-end "
            style={{
              bottom: "0px",
              width: "100%",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div
              className="next-navigation col-5"
              style={{ cursor: "pointer" }}
              onClick={() => navigate('/api-overview')}
            >
              <div style={{ cursor: "pointer" }}>
                <p className="m-0 text-white text-right font-body">Next</p>
                <h6 className="text-primary font-body">📄 API Overview &gt;&gt;</h6>
              </div>
            </div>
          </div>

          {/* support */}
          <div className='support-container font-body'>
        <div className='link-text text-center'>Need help or have questions? Contact us at <a href="mailto:hello@xrcouture.com">hello@xrcouture.com</a> and we’ll get back to you as soon as possible.</div>
      </div>

        </main>



        {/* submenu */}
        {/* {mob && <div style={{width:"15%", height:"100%", position:"fixed", right:0}}>
          <div className='submenu-container link-text'>
            <a href='#api-overview'>API Overview</a>
            <a href='#goal-and-vision'>Goal & Vision</a>
            <a href='#key-features'>Key Features</a>
            <a href='#avatar-standard'>Wearable Standards</a>
            <a href='#benefits'>Benefits</a>
          </div>
        </div>} */}
      </div>
    </div>
  );
}

export default GettingStarted;
