import React, { useEffect, useState } from 'react'
import Submenu from '../Submenu/Submenu'
import './overview.css'
import image1 from './1.png'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {MdOutlineNavigateNext} from 'react-icons/md'
import { IoMdHome } from 'react-icons/io';
import {GrFormPreviousLink,GrFormNextLink} from 'react-icons/gr'
import { useNavigate } from 'react-router';

function Overview() {

  //dropdown states
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [mob,setMob] = useState(false)
  useEffect(()=>{
    if (window.screen.width <= 1000) {
      setMob(false)
    }else{
      setMob(true)
    }
  },[])
  useEffect(()=>{
    // scroll to the top of the page smoothly
        window.scrollTo({ top: 0, behavior: "smooth" });
      },[])
  const navigate = useNavigate()
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.my-section');
      const links = document.querySelectorAll('.my-link');

      sections.forEach((section, index) => {
        if (window.scrollY >= section.offsetTop) {
          links.forEach(link => link.classList.remove('highlight'));
          links[index].classList.add('highlight');
        }
      });
    };
    if(window.innerWidth > 1000){
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }


  }, []);

  return (
    <div className='d-flex main-content-container' >
        <main style={{width:mob ? "80%" : "100%",paddingRight:"4%"}} >

          {/* custom breadcrumb */}
          <div className='breadcrumb-overview d-flex flex-row align-items-center' style={{width:"fit-content"}}>
            <div className='home-icon' onClick={()=>navigate('/')}><IoMdHome color='white' style={{color:"white",cursor:"pointer"}} className='link-text '/></div>
            <MdOutlineNavigateNext color='white' style={{color:"white"}} size={20} className='link-text'  />
            <div className='font-body' style={{color:"#3578e5"}}>📄 Overview</div>
            <br />
          </div>
         
          {/* dropdown */}
            <div className="d-flex dropdown-section mt-2 mb-2 w-100">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"} className='w-100'>
              <DropdownToggle className='w-100 d-flex justify-content-between align-items-center' style={{background:"#272829",opacity:1, border:"1px solid #444950"}} caret>On this Page</DropdownToggle>
              <DropdownMenu className='w-100' style={{background:"#272829", color:"white !important",position:"relative"}}>
                <DropdownItem> <a style={{color:"#fbfbfb"}} className='font-body' href='#api-overview'>API Overview</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#goal-and-vision'>Goal & Vision</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#key-features'>Key Features</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#avatar-standard'>Wearable Standards</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#benefits'>Benefits</a></DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

            {/* overview */}
            <div className='my-section'>
            <p className='text-header' id='api-overview'>📄 API Overview</p>
            <p className='font-body'>Welcome to the XR Couture API – an innovative solution for metaverse and avatar platforms to integrate our extensive collection of fashion wearables into their ecosystems. With our API, developers can effortlessly access a diverse range of fashion items and provide their users with a captivating and customizable virtual wardrobe.</p>
            <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/1.jpg" width="80%" style={{marginLeft:"10%"}} />
            </div>
            <hr></hr>
            {/* Goals and Vision */}
            <div className='my-section'>
            <p className='text-header mt-5' id='goal-and-vision'>🎯 Goal & Vision</p>
            <p className='font-body'>At XR Couture, our API aims to revolutionize the fashion experience within metaverse and avatar platforms. Our goal is to provide a seamless and comprehensive solution that enhances value for virtual assets, contributes to an open metaverse, and fosters collaborative partnership</p>
            <p className='text-sub-heading'>1. Open Metaverse & 3D Standards</p>
            <p className='font-body'>By working with multiple metaverse and avatar platforms, we collect vital information to develop an open metaverse and interoperability standards. We believe in the importance of collaboration and cooperation across platforms to create a more inclusive and interconnected Metaverse. As a member of the Metaverse Standard Forum, we actively collaborate with industry pioneers such as Nvidia, Unity, Epic Games, and more to create open standards that facilitate seamless interaction and compatibility between different platforms. By integrating our API, you join us in shaping the future of the metaverse and promoting interoperability.</p>
            <p className='text-sub-heading'>2. Value Creation for Virtual Assets</p>
            <p className='font-body'>Our API enables cross-platform usage of fashion wearables, adding value to users assets. By integrating our API, users can showcase and utilize their fashion collections across different platforms, expanding their reach, and enhancing the value and utility of their virtual assets.</p>
            <p className='text-sub-heading'>3. Collaborative Growth & Partnerships</p>
            <p className='font-body'>Our free API installation fosters collaboration and partnership opportunities within the virtual fashion industry. By integrating our API, you join a collaborative network, contributing to the growth and vibrancy of the metaverse ecosystem as a whole.</p>
            <p className='font-body'>By integrating our API, you transform the virtual fashion landscape, enhance user experiences, contribute to an open metaverse, and add value to users virtual assets. Let's embark on this transformative journey together and unlock the full potential of virtual fashion!</p>
            </div>
            <hr></hr>

            {/* Key Features */}  
            <div className='my-section'>
            <p className='text-header mt-5' id='key-features'>🌟 Key Features</p>
            <p className='font-body'>Our API offers a range of powerful features designed to streamline the integration process and enhance the fashion experience within metaverse and avatar platforms. Some key features include:</p>
            <ul className='font-body'>
              <li><b>Seamless Access:</b> Our API provides a seamless and efficient method for fetching fashion wearables, eliminating the need for complex data handling or manual updates.</li>
              <li><b>Extensive Collection:</b> Discover a vast collection of fashion wearables encompassing various styles, trends, and categories. From clothing and accessories to hairstyles and cosmetics, our API covers it all.</li>
              <li><b>Regular Updates:</b> We are committed to keeping our collection up to date. Expect new additions, seasonal releases, and trend-driven updates, ensuring a fresh and exciting fashion ecosystem for your users.</li>
              <li><b>Easy Integration:</b> We have designed our API with simplicity in mind, making the integration process smooth and hassle-free. Developers can quickly integrate the API into their metaverse or avatar platforms, saving time and effort.</li>
            </ul>
            </div>
            <hr></hr>

            {/* Wearable Standards */}
            <div className='my-section'>

            <p className='text-header mt-5' id='avatar-standard'>👥 Wearable Standards</p>
            <p className='font-body'>Our database contains a variety of supported standards for avatars. If your avatar meets any of these standards, you can enjoy the entire <a href="https://metadrip.xrcouture.com/" target="_blank">MetaDrip</a> collection at no cost. You also have the option to request 3D files and create your own standard. Alternatively, our team can create a custom standard for you. <a href='mailto:hello@xrcouture.com' target='_blank'>Get in Touch.</a></p>
            <img src='https://camo.githubusercontent.com/b4003c56467a4fff731820a146de88b6716ff7f3198cb4172108a18fef5f22e7/68747470733a2f2f6d656469612e646973636f72646170702e6e65742f6174746163686d656e74732f313038373330373133303935383235303030342f313130343032373736313939333036303337322f47726f75705f362e706e673f77696474683d31303031266865696768743d353332' width="70%" style={{marginLeft:"15%"}} />
            <div className='font-body'>List of available standards</div>
            <ul>
              <li>
              <div className='font-body'>Decentraland</div>
              </li>
              <li>
              <div className='font-body'>Zepeto</div>
              </li>
              <li>
              <div className='font-body'>Roblox Layered Clothing</div>
              </li>
              <li>
              <div className='font-body'>Roblox Classic Clothing</div>
              </li>
              <li>
              <div className='font-body'>Clone X</div>
              </li>
              <li>
              <div className='font-body'>Minecraft</div>
              </li>

              <li>
              <div className='font-body'>OVeR</div>
              </li>
              <li>
              <div className='font-body'>SnapChat</div>
              </li>
              <li>
              <div className='font-body'>Sandbox</div>
              </li>
              <li>
              <div className='font-body'>ReadyPlayerMe</div>
              </li>
            </ul>
            <p className='font-body'>If you don't see your supported standards on the list, please reach out to us at <a href='mailto:hello@xrcouture.com' target='_blank'>hello@xrcouture.com</a> , and we'll be happy to explore possibilities for integration.</p>
            </div>
            <hr></hr>

            {/* Benefits */}
            <div className='my-section'>
            <p className='text-header mt-5' id="benefits">🎁 Benefits of Installing the API</p>
            <p className='font-body'>By installing our API and integrating our fashion wearables collection into your metaverse or avatar platform, you can <b>unlock a multitude of benefits that will help attract new users and support cross-platform usage.</b> Let's explore some of these advantages:</p>
            <p className='text-sub-heading'>1. Access to a Vast Fashion Wearables Collection</p>
            <p className='font-body'>Installing our API grants you access to a vast and ever-expanding collection of fashion wearables. Offering a diverse range of clothing, accessories, hairstyles, and cosmetics, you can cater to a wide variety of user preferences, styles, and fashion trends. This extensive collection will captivate existing users and entice new users to join your platform.</p>
            <p className='text-sub-heading'>2. Attract and Engage Fashion Enthusiasts</p>
            <p className='font-body'>By integrating our API, you position your platform as a go-to destination for fashion-forward individuals. The availability of an extensive fashion wearables collection will attract fashion enthusiasts, encouraging them to join and engage with your platform. This can lead to increased user retention and higher engagement rates.</p>
            <p className='text-sub-heading'>3. Enhanced Customization and Self-Expression</p>
            <p className='font-body'>Installing our API empowers your users to customize their avatars with a wide range of fashion wearables. This enhanced customization fosters a sense of ownership and self-expression, creating a more immersive and engaging user experience. Users will appreciate the freedom to curate their unique virtual style, expanding your user base.</p>
            <p className='text-sub-heading'>4. Cross-Platform Compatibility</p>
            <img src={image1} width="80%%" style={{marginLeft:"10%",marginTop:"3%", marginBottom:"3%"}} />
            <p className='font-body'>Our API supports cross-platform usage, allowing seamless integration across various metaverse and avatar platforms. This compatibility enables users to access and utilize your fashion wearables collection regardless of the platform they choose. By offering cross-platform compatibility, you broaden your user reach and eliminate barriers that might restrict users from experiencing your fashion ecosystem.</p>
            <p className='text-sub-heading'>5. Amplify User Acquisition and Retention</p>
            <p className='font-body'>The availability of a diverse fashion wearables collection, combined with cross-platform compatibility, positions your platform as an attractive destination for users seeking a comprehensive virtual fashion experience. This amplifies user acquisition and retention rates, creating a thriving and vibrant user community.</p>
            <p className='font-body'>By installing our API and integrating our fashion wearables collection, you attract new users, cater to fashion enthusiasts, foster self-expression, and support cross-platform usage. <b>These benefits contribute to the growth and success of your metaverse or avatar platform as a leading destination for virtual fashion experiences.</b></p>
            <p className='font-body'>In the next sections, we'll provide detailed instructions on getting started, an API reference, code examples, and answers to frequently asked questions. Let's revolutionize the fashion landscape within the metaverse together!</p>
            </div>

        {/* naivation */}
        <div className='static-navigation d-flex align-items-center row justify-content-around flex-row m-0' style={{bottom:"0px",width:"100%",paddingTop:"20px",paddingBottom:"20px"}} >

        <div className='prev-navigation flex-row col-md-5 col-5 justify-content-between' style={{cursor:"pointer"}}  onClick={()=>navigate('/')}>
        <div>
          <span className='m-0 text-white font-body' >Previous</span>
          <h6 className='text-primary font-body'>&lt;&lt; 🚀Getting Started</h6>   
        </div>
        
        </div>
        <div className='next-navigation col-md-5 col-5 justify-content-between' style={{cursor:"pointer"}} onClick={()=>navigate('/api')}>
        <div  style={{cursor:"pointer"}}>
          <span className='m-0 text-white font-body' >Next</span>
          <h6 className='text-primary font-body'>🔑 REST API &gt;&gt;</h6>  
        </div>
        </div>
</div>   

      <div className='support-container font-body'>
        <div className='link-text text-center'>Need help or have questions? Contact us at <a href="mailto:hello@xrcouture.com">hello@xrcouture.com</a> and we’ll get back to you as soon as possible.</div>
      </div>

        </main>



        {/* submenu */}

       {mob && <div style={{width:"15%", height:"100%", position:"fixed", right:0}}>
          <div className='submenu-container link-text'>
            <a href='#api-overview' className="my-link">API Overview</a>
            <a href='#goal-and-vision' className="my-link">Goal & Vision</a>
            <a href='#key-features' className="my-link">Key Features</a>
            <a href='#avatar-standard' className="my-link">Wearable Standards</a>
            <a href='#benefits' className="my-link">Benefits</a>
          </div>
        </div>}
    </div>
  )
}

export default Overview