import React,{useState,useEffect} from 'react'
import './Collections.css'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
  import {MdOutlineNavigateNext} from 'react-icons/md'
import { IoMdHome } from 'react-icons/io';
import { useNavigate } from 'react-router';
import HoverVideoPlayer from 'react-hover-video-player';
import { items } from "./utility" 


function Collections() {

    const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const history = useNavigate()

  const [mob,setMob] = useState(false)
  var gen = []

  for(let i=1; i<31;i++){
    gen.push(i)
  }

  useEffect(()=>{

    if (window.screen.width <= 1000) {
      setMob(false)
    }else{
      setMob(true)
    }
  },[])

  const handleClick = () => {
    history.push('/api-overview');
    const element = document.getElementById('avatar-standard');
    element.scrollIntoView();
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.my-section');
      const links = document.querySelectorAll('.my-link');

      sections.forEach((section, index) => {
        if (window.scrollY >= section.offsetTop) {
          links.forEach(link => link.classList.remove('highlight'));
          links[index].classList.add('highlight');
        }
      });
    };
    if(window.innerWidth > 1000){
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(()=>{
    // scroll to the top of the page smoothly
        window.scrollTo({ top: 0, behavior: "smooth" });
      },[])
  const navigate = useNavigate()

  var itemsAll = ["Chrome_Heart","Flora_Flamboyance","Puffy_Crossroads","Oyster_Spell","Vibrance_Splash","Rufflanza","Star_Cloak","Celestial_Dream","Pop_Kiss","Dazzling_Devil","Comic_Boom","Human_Masquerade"]


  return (
    <div>
            <div className='d-flex main-content-container' >
        <main style={{width:mob ? "80%" : "100%",paddingRight:"4%"}} >
        {/*  */}
          {/* custom breadcrumb */}
          <div className='breadcrumb-overview d-flex flex-row align-items-center' style={{width:"fit-content"}}>
            <div className='home-icon' onClick={()=>navigate('/')}><IoMdHome color='white' style={{color:"white",cursor:"pointer"}} className='link-text '/></div>
            <MdOutlineNavigateNext color='white' style={{color:"white"}} size={20} className='link-text'  />
            <div className='font-body' style={{color:"#3578e5"}}>👩‍🦳 Collections</div>
            <br />
          </div>
         
          {/* dropdown */}
            <div className="d-flex dropdown-section mt-2 mb-2 w-100">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"} className='w-100'>
              <DropdownToggle className='w-100 d-flex justify-content-between align-items-center' style={{background:"#272829",opacity:1, border:"1px solid #444950"}} caret>On this Page</DropdownToggle>
              <DropdownMenu className='w-100' style={{background:"#272829", color:"white !important",position:"relative"}}>
                <DropdownItem> <a style={{color:"#fbfbfb"}} className='font-body' href='#metadrip'>MetaDrip</a></DropdownItem>
                <DropdownItem> <a style={{color:"#fbfbfb"}} className='font-body' href='#genesis'>Genesis</a></DropdownItem>

              </DropdownMenu>
            </Dropdown>
          </div>

            {/* overview */}
            <p className='text-header' id='api-overview'>Collections</p>
            {/* <p className='font-body'>Welcome to the XR Couture API – an innovative solution for metaverse and avatar platforms to integrate our extensive collection of fashion wearables into their ecosystems. With our API, developers can effortlessly access a diverse range of fashion items and provide their users with a captivating and customizable virtual wardrobe.</p> */}
            <hr></hr>

   <div className="my-section">
    <h3 className='text-sub-heading' id='metadrip'>MetaDrip</h3>
    <div className='row' style={{marginTop:"2em"}}>
    {itemsAll.map(item=>(
                <div className='col-md-2 col-sm-4 col-xs-12 d-flex justify-content-center mb-4 position-relative' key={item}>
                  {/* <p className='item-name'>{items[item].name}</p> */}
                  <HoverVideoPlayer
                                videoSrc={items[item].video}
                                style={{width:"100%"}}
                                className="meta-video"
                                preload="metadata"
                                pausedOverlay={
                                  <video  src={items[item].video} playsInline style={{width:"100%", cursor:"pointer"}} alt={item} loop muted/>             
                                }
                                loadingOverlay={
                                    <div className="loading-overlay">
                                    <div className="loading-spinner" />
                                    </div>
                                }
                                />
                        </div>
            ))}
            </div>
    <p className='ff-l fs-sm mt-2 tc-l'>Our database contains a variety of <a onClick={handleClick}>supported standards</a> for avatars. You also have the option to <a href='mailto:hello@xrcouture.com?subject=Request%203D%20Files' target='_blank' >Request 3D files</a> and upload your own standard. Alternatively, our team can create a custom standard for you. <a href='mailto:hello@xrcouture.com' target='_blank'>Get in Touch.</a></p>
  </div>
  <hr></hr>


    <div className="my-section">
      <h3 className='text-sub-heading' id='genesis'>Genesis</h3>
        <div className='row' style={{marginTop:"2em"}}>
        {gen.map(i=>(
                    <div className='col-md-2 col-sm-4 col-xs-12 d-flex justify-content-center mb-4 position-relative' key={i}>
                      <img className='gen-image' src={`https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/web2collection/${i}.webp`} width="100%" alt={i} />
                            </div>
                ))}
                </div>
        <p className='font-body'>Check out our exclusive Genesis collection! For more information, contact us at <a href="mailto:hello@xrcouture.com" target='_blank'>hello@xrcouture.com</a></p>
        </div>
        {/* naivation */}
        <div className='static-navigation d-flex align-items-center row justify-content-around flex-row m-0' style={{bottom:"0px",width:"100%",paddingTop:"20px",paddingBottom:"20px"}} >

        <div className='prev-navigation flex-row col-12 col-md-5 justify-content-between ' style={{cursor:"pointer"}} onClick={()=>navigate('/api')}>
        <div>
          <p className='m-0 text-white font-body' >Previous</p>
          <h6 className='text-primary font-body'>&lt;&lt; 🔑 REST API</h6>   
        </div>
        
        </div>
        <div className='next-navigation justify-content-between col-10 col-md-5 d-none' style={{cursor:"pointer",visibility:"hidden"}}>
        <div  style={{cursor:"pointer"}}>
          <p className='m-0 text-white' >Next</p>
          <h6 className='text-white'>Rest API &gt;&gt;</h6>  
        </div>
        </div>
</div>   

      <div className='support-container font-body'>
        <div className='link-text text-center'>Need help or have questions? Contact us at <a href="mailto:hello@xrcouture.com">hello@xrcouture.com</a> and we’ll get back to you as soon as possible.</div>
      </div>

        </main>



        {/* submenu */}

       {mob && <div style={{width:"15%", height:"100%", position:"fixed", right:0}}>
          <div className='submenu-container link-text'>
            <a href='#metadrip' className="my-link">MetaDrip</a>
            <a href='#genesis' className="my-link">Genesis</a>
          </div>
        </div>}
    </div>
    </div>
  )
}

export default Collections