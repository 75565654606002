import React from 'react'
import './Submenu.css'

function Submenu() {
  return (
    <div className='submenu-container link-text'>
        <p>Submenu 1</p>
        <p>Submenu 2</p>
        <p>Submenu 3</p>
        <p>Submenu 4</p>
        <p>Submenu 5</p>
    </div>
  )
}

export default Submenu