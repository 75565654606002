import React, { useEffect, useState } from 'react'
import './RestApi.css'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import img1 from './XRC_Hoodie.png'
import {MdOutlineNavigateNext} from 'react-icons/md'
import { IoMdDownload, IoMdHome } from 'react-icons/io';
import { useNavigate } from 'react-router';
import {Link} from 'react-router-dom'
import { CopyBlock, dracula, nord, a11yDark,solarizedDark, CodeBlock, anOldHope, androidstudio, arta, tomorrow, tomorrowNight, zenburn, xt256, vs2015, tomorrowNightEighties, tomorrowNightBlue, tomorrowNightBright, sunburst, rainbow, railscast } from "react-code-blocks";
import { BiCopy } from 'react-icons/bi';
import copy from 'copy-to-clipboard';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Toaster,toast } from 'react-hot-toast';
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios';

function RestAPI() {

  const [modal, setModal] = useState(false);

    const apikey = "apiKey"
    const platformName = "platformName"
    const walletAddress = "walletAdress"

  //dropdown states
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const showLineNumbers = Boolean('showLineNumbers', true);
const codeBlock = Boolean('codeBlock', true);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [mob,setMob] = useState(false)
  useEffect(()=>{
// scroll to the top of the page smoothly
    window.scrollTo({ top: 0, behavior: "smooth" });
  },[])
  useEffect(()=>{
    if (window.screen.width <= 1000) {
      setMob(false)
    }else{
      setMob(true)
    }
  },[])



  const testFiles = {
    "Decentraland": "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_DCL.glb",
    "Minecraft" : "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_Minecraft.png",
    "Ovr" : "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_OVR.glb",
    "Roblox_Classic" : "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_Roblox_Classic.png",
    "Roblox" : "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_Roblox.rbxm",
    "Ready_Player_Me" : "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_RPM.glb",
    "Sandbox":"https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_Sandbox.zip",
    "VRM_File" : "https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_VRM.vrm",
    "VDROID_File":"https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_VROID.vroid",
    "Zepeto":"https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/assets/XRC_Hoodie/XRC_Hoodie_Zepeto.zepeto"
  }
  const downloadFile = () => {
    const selectElement = document.getElementById('inputGroupSelect04');
    if (selectElement.value!=="") {
      const url = testFiles[selectElement.value];
  
      const link = document.createElement('a');
      link.href = url;
      link.download = '';
      link.target='_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      selectElement.value = '';
    } else {
      alert('Please select a value from the dropdown');
    }
  };
  const [email,setEmail] = useState("")
  const [loading,setLoading] = useState(false)

  const handleSubmitMail = async(e) => {
   
  }
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.my-section');
      const links = document.querySelectorAll('.my-link');

      sections.forEach((section, index) => {
        if (window.scrollY >= section.offsetTop) {
          links.forEach(link => link.classList.remove('highlight'));
          links[index].classList.add('highlight');
        }
      });
    };

    if(window.innerWidth > 1000){
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  
  
  const navigate = useNavigate()
  return (
    <div className='d-flex main-content-container text-justify' >
        <main style={{width:mob ? "80%" : "100%",paddingRight:"4%"}} >

          {/* custom breadcrumb */}
          <div className='breadcrumb-overview d-flex flex-row align-items-center' style={{width:"fit-content"}}>
            <div className='home-icon' onClick={()=>navigate('/')}><IoMdHome color='white' style={{color:"white",cursor:"pointer"}} className='link-text '/></div>
            <MdOutlineNavigateNext color='white' style={{color:"white"}} size={20} className='link-text'  />
            <div className='font-body' style={{color:"#3578e5"}}>🗄️ Rest API</div>
            <br />
          </div>
         
          {/* dropdown */}
            <div className="d-flex dropdown-section mt-2 mb-2 w-100">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"} className='w-100'>
              <DropdownToggle className='w-100 d-flex justify-content-between align-items-center' style={{background:"#272829",opacity:1, border:"1px solid #444950"}} caret>On this Page</DropdownToggle>
              <DropdownMenu className='w-100' style={{background:"#272829", color:"white !important",position:"relative"}}>
                <DropdownItem> <a style={{color:"#fbfbfb"}} className='font-body' href='#rest-api'>Rest API</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#rest-api-web3'>Rest API(Web3)</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#key-features'>Rest API(Web2)</a></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><a style={{color:"#fbfbfb"}} className='font-body' href='#test-api'>How to test API</a></DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

            {/* overview */}
            <div className="my-section">
            <p className='text-header' id='rest-api'>🗄️ REST API</p>
            <p className='font-body'>The platforms will be provided with a REST API and these platforms can be used to retrieve the wearables/digital fashion asset and show it in their own marketplace or under users profile in the metaverse.</p>
            <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/2.jpg" width="50%" style={{marginLeft:"25%"}} />
            <p className='text-sub-heading'>Access and authentication</p>
            <p className='font-body'>All API request is sent over HTTPS and accessed from <a href="https://api.model.metadrip.xrcouture.com" target='_blank'>https://api.model.metadrip.xrcouture.com</a></p>
            <p className='font-body'>The API access is restricted and it needs an API key to access it. <a href='mailto:hello@xrcouture.com?subject=Request%20API%20Key'>Mail us</a> to get an API key. </p>
           <hr></hr>
           </div>

         {/* restAPI (web3) */}
         <div className="my-section">
            <p className='text-header mt-5' id='rest-api-web3'>🌐 REST API (Web3)</p>
            <p className='text-sub-heading'>Format</p>        
            <p className='font-body'>The API request should be in below format</p>
            <p className='font-body text-wrap' style={{wordBreak:"break-all"}}><a href="https://api.model.metadrip.xrcouture.com" target='_blank'><span className='link-text' style={{float:"left",padding:"2px 3px",marginRight:"3px", background:"#3578e5",color:"white",borderRadius:"3px"}}>GET</span> https://api.model.metadrip.xrcouture.com/web3/v1/{apikey}/platform/{platformName}/address/{walletAddress}
            </a></p>
            <p className='font-body'>Where,</p>

            <ul style={{marginLeft:"35px"}}>
                <li>
                <div className='font-body'>{"{apikey} => unique key to access the api"}</div>
                </li>
                <li>
                <div className='font-body'>{"{platformName} => platformName from the list of supported platforms mentioned below"}</div>
                <div className='font-body' style={{opacity:.7}}>
                <ul style={{marginLeft:"5px"}}>
                <li>
                <div className='link-text'>decentraland</div>
                </li>
                <li>
                <div className='link-text'>sandbox</div>
                </li>
                <li>
                <div className='link-text'>roblox</div>
                </li>
                <li>
                <div className='link-text'>snapchat</div>
                </li>
                <li>
                <div className='link-text'>zepeto</div>
                </li>
                <li>
                <div className='link-text'>ovr</div>
                </li>

                <li>
                <div className='link-text'>clonex</div>
                </li>
                <li>
                <div className='link-text'>megaverse</div>
                </li>
                <li>
                <div className='link-text'>robloxclassic</div>
                </li>
                <li>
                <div className='link-text'>minecraft</div>
                </li>
                <li>
                <div className='link-text'>readyplayerme</div>
                </li>
            </ul>
            </div>
                </li>
                
                <li>
                <div className='font-body'>{"{walletAddress} => wallet address of the owner of the metadrip collection"}</div>
                </li>

            </ul>

            <p className='font-body'>Example,</p>
            <p className='font-body highlight' style={{wordBreak:"break-all"}}><a style={{wordBreak:"break-word"}}>
                https://api.model.metadrip.xrcouture.com/web3/v1/xrc-abcdefghijklmnopqrst/platform/decentraland/address/0xcd8c097cC2331EeF50074bf69F7eD26e7896D48b
            </a></p>

            <p className='text-sub-heading'>Response</p>        
            <p className='font-body'>The response to this request will in Json format</p>
            <div className='d-flex align-items-center justify-content-center' style={{width:"80%",marginLeft:"10%"}}>

            <CodeBlock
            text={`         [
                    {
                    "name": "Dazzling Devil",
                    "description": "Straight from the streets of LA, the mind-blowing outfit by Lucii is ready to give your virtual closet a blend of futuristic and gala vibes. The multi-colored flashlights, the leather finesse, and the ‘ready-to-rock’ boots underline the designer’s cutting-edge creativity and imagination. Be the multi-hued spotlight in the Metaverse!",
                    "image": "https://nft.xrcouture.com/artgallery/phase2/Lucii/3.png",
                    "animation_url": "ipfs://bafybeieqtrzqyk4nawljawdgd5vfmv5wtcnmfxewlyanznvyecltdrb47q",
                    "id": {
                        "platform": "decentraland",
                        "chain": "matic",
                        "address": "0x99d6c0d1a656a1ee1f345ae6482d0afd76daf8a5"
                    },
                    "wearables": "https://metadrip.xrcouture.com/*******/*********/Dazzling_Devil_DCL.glb",
                    "category": "Upper Body"
                    }
                ]`}
                language={'json'}
                wrapLines={true}
                wrapLongLines={true}
                showLineNumbers={false}
                theme={railscast}
                customStyles={
                    {
                        height: '250px',
                        overflowY: 'scroll',
                        margin: '0px 0.75rem',
                        borderRadius: '5px',
                        boxShadow: '1px 2px 3px rgba(0,0,0,0.35)',
                        fontSize: '0.75rem',
                        color:"white"
                    }
                }
                codeBlock
                icon={<BiCopy />}
                onCopy={() => {
                    alert("Copied to Clipboard")
                    copy(`[
                        {
                        "name": "Dazzling Devil",
                        "description": "Straight from the streets of LA, the mind-blowing outfit by Lucii is ready to give your virtual closet a blend of futuristic and gala vibes. The multi-colored flashlights, the leather finesse, and the ‘ready-to-rock’ boots underline the designer’s cutting-edge creativity and imagination. Be the multi-hued spotlight in the Metaverse!",
                        "image": "https://nft.xrcouture.com/artgallery/phase2/Lucii/3.png",
                        "animation_url": "ipfs://bafybeieqtrzqyk4nawljawdgd5vfmv5wtcnmfxewlyanznvyecltdrb47q",
                        "id": {
                            "platform": "decentraland",
                            "chain": "matic",
                            "address": "0x99d6c0d1a656a1ee1f345ae6482d0afd76daf8a5"
                        },
                        "wearables": "https://metadrip.xrcouture.com/*******/*********/Dazzling_Devil_DCL.glb",
                        "category": "Upper Body"
                        }
                    ]`)}}
            />
            </div>
            <hr></hr>
         </div>



            {/* restAPI (web2) */}
            <div className="my-section">
            <p className='text-header mt-5' id='rest-api-web2'>🔗 REST API (Web2)</p>
            <p className='font-body'>The web2 platforms will be provided with a REST API and these platforms can pass the users email address in the API to get the users assets</p>
            <p className='text-sub-heading'>Format</p>        
            <p className='font-body'>The API request should be in below format</p>
            <p className='font-body' style={{wordBreak:"break-all"}}><a href="https://api.model.metadrip.xrcouture.com" target='_blank'><span className='link-text' style={{float:"left",padding:"2px 3px",marginRight:"3px", background:"#3578e5",color:"white",borderRadius:"3px"}}>GET</span>{" https://api.model.metadrip.xrcouture.com/web2/v1/{apikey}/platform/{platformName}/email/{emailAddress}/"}
            </a></p>
            <p className='font-body'><b>Note: Please make sure to include a "/" at the end of the URL.</b></p>
            <p className='font-body'>Where,</p>

            <ul style={{marginLeft:"35px"}}>
                <li>
                <div className='font-body'>{"{apikey} => unique key to access the api"}</div>
                </li>
                <li>
                <div className='font-body'>{"{platformName} => platformName from the list of supported platforms mentioned below in the document"}</div>
                </li>
                
                <li>
                <div className='font-body'>{"{emailAddress} => email address of the user who owns the asset"}</div>
                </li>

            </ul>

            <p className='font-body'>Example,</p>
            <p className='font-body' style={{wordBreak:"break-all"}}><a href="https://api.model.metadrip.xrcouture.com/web2/v1/xrc-abcdefghijklmnopqrst/platform/decentraland/email/abc@xyz.com/" target='_blank'>
            https://api.model.metadrip.xrcouture.com/web2/v1/xrc-abcdefghijklmnopqrst/platform/decentraland/email/abc@xyz.com/
            </a></p>

            <p className='text-sub-heading'>Response</p>        
            <p className='font-body'>The response to this request will in Json format</p>
            <div className='d-flex align-items-center justify-content-center' style={{width:"80%",marginLeft:"10%"}}>

            <CodeBlock
            text={`        [
                {
                "name": "Dazzling Devil",
                "description": "Straight from the streets of LA, the mind-blowing outfit by Lucii is ready to give your virtual closet a blend of futuristic and gala vibes. The multi-colored flashlights, the leather finesse, and the ‘ready-to-rock’ boots underline the designer’s cutting-edge creativity and imagination. Be the multi-hued spotlight in the Metaverse!",
                "image": "https://nft.xrcouture.com/artgallery/phase2/Lucii/3.png",
                "animation_url": "ipfs://bafybeieqtrzqyk4nawljawdgd5vfmv5wtcnmfxewlyanznvyecltdrb47q",
                "id": {
                    "platform": "decentraland",
                    "chain": "matic",
                    "address": "0x99d6c0d1a656a1ee1f345ae6482d0afd76daf8a5"
                },
                "wearables": "https://metadrip.xrcouture.com/*******/*********/Dazzling_Devil_DCL.glb",
                "category": "Upper Body"
                }
            ]`}
                language={'json'}
                wrapLines={true}
                wrapLongLines={true}
                showLineNumbers={false}
                theme={railscast}
                customStyles={
                    {
                        height: '250px',
                        overflowY: 'scroll',
                        margin: '0px 0.75rem',
                        borderRadius: '5px',
                        boxShadow: '1px 2px 3px rgba(0,0,0,0.35)',
                        fontSize: '0.75rem',
                        color:"white"
                    }
                }
                codeBlock
                icon={<BiCopy />}
                onCopy={() => {
                    alert("Copied to Clipboard")
                    copy(`    [
                        {
                        "name": "Dazzling Devil",
                        "description": "Straight from the streets of LA, the mind-blowing outfit by Lucii is ready to give your virtual closet a blend of futuristic and gala vibes. The multi-colored flashlights, the leather finesse, and the ‘ready-to-rock’ boots underline the designer’s cutting-edge creativity and imagination. Be the multi-hued spotlight in the Metaverse!",
                        "image": "https://nft.xrcouture.com/artgallery/phase2/Lucii/3.png",
                        "animation_url": "ipfs://bafybeieqtrzqyk4nawljawdgd5vfmv5wtcnmfxewlyanznvyecltdrb47q",
                        "id": {
                            "platform": "decentraland",
                            "chain": "matic",
                            "address": "0x99d6c0d1a656a1ee1f345ae6482d0afd76daf8a5"
                        },
                        "wearables": "https://metadrip.xrcouture.com/*******/*********/Dazzling_Devil_DCL.glb",
                        "category": "Upper Body"
                        }
                    ]`)}}
            />
            </div>
            <hr></hr>
            </div>
            
             {/* how to test API */}
            <div className="my-section">
            <p className='text-header mt-5' id='test-api'>⚙️ How to Test the API</p>
                    <div className='row align-items-start'>
                      <div className='col-12 col-md-6'>
                      <p className='font-body'>
                    <div className='ff-l fs-sm mt-2 tc-l '>Our database contains a variety of supported standards for the test asset. If your avatar meets any of these standards, you can test the asset. You also have the option to download 3D files and Upload your own standard.  Alternatively, our team can create a custom standard for you. <a href='mailto:hello@xrcouture.com?subject=Requesting%20Custom%203D%20Asset' target='_blank'>Get in Touch.</a></div>
                    <div class="input-group mt-2  w-30 d-none d-md-flex" data-bs-theme="dark" style={{flexGrow:1,}}>
                    <select class="custom-select p-2" id="inputGroupSelect04" style={{flexGrow:1, borderRadius:"5px 0px 0px 5px",paddingRight:"10px"}}> 
                      <option selected disabled value="">Choose Platform</option>
                      <option value="Decentraland">Decentraland</option>
                      <option value="Minecraft">Minecraft</option>
                      <option value="Ovr">Ovr</option>
                      <option value="Roblox_Classic">Roblox Classic</option>
                      <option value="Roblox">Roblox</option>
                      <option value="Ready_Player_Me">Ovr</option>
                      <option value="Sandbox">Sandbox</option>
                      <option value="VRM_File">VRM</option>
                      <option value="VROID_File">VROID</option>
                      <option value="Zepeto">Zepeto</option>
                    </select>
                    <div class="input-group-append" >
                      <button class="btn btn-success m-0" onClick={downloadFile} style={{borderRadius:"0px 5px 5px 0px",padding:"5px 10px !important"}} type="button">Download<IoMdDownload /></button>
                    </div>
                    </div>
                    </p>

                      </div>
                      <div className='col-12 col-md-6 p-2'>
                      <img width="80%" src={img1} />
                      <div class="input-group mt-2  w-30 d-flex d-md-none" data-bs-theme="dark" style={{flexGrow:1,}}>
                    <select class="custom-select p-2" id="inputGroupSelect04" style={{flexGrow:1, borderRadius:"5px 0px 0px 5px",paddingRight:"10px"}}> 
                      <option selected disabled value="">Choose Platform</option>
                      <option value="Decentraland">Decentraland</option>
                      <option value="Minecraft">Minecraft</option>
                      <option value="Ovr">Ovr</option>
                      <option value="Roblox_Classic">Roblox Classic</option>
                      <option value="Roblox">Roblox</option>
                      <option value="Ready_Player_Me">Ovr</option>
                      <option value="Sandbox">Sandbox</option>
                      <option value="VRM_File">VRM</option>
                      <option value="VROID_File">VROID</option>
                      <option value="Zepeto">Zepeto</option>
                    </select>
                    <div class="input-group-append" >
                      <button class="btn btn-success m-0" onClick={downloadFile} style={{borderRadius:"0px 5px 5px 0px",padding:"5px 10px !important"}} type="button">Download<IoMdDownload /></button>
                    </div>
                    </div>
                      </div>

                  </div>
                    <div className='text-sub-heading mt-2'>Web3 API</div> 
                    <ol type='1' style={{marginLeft:"25px"}}>
                    <li >
                    <div className='mt-2 font-body '>
                    Mint a NFT (Polygon)
                    </div>
                    <ol type='1'>
                    <li>
                    <div className='mt-2 font-body'>
                    You have to mint a NFT for yourself <a href="https://polygonscan.com/address/0x99baf06205d8b8812680298edc06b8ff7f96894f#writeContract" target="_blank">here </a>
                    </div>
                    </li>
                    <li>
                    <div className='mt-2 font-body'>
                    Connect your wallet
                    </div>
                    <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/image1.png" width="70%" style={{marginLeft:"5%"}} />
                    </li>
                    <li>
                    <div className='font-body mt-3' style={{wordBreak:"break-word"}}>
                    Call the safeMint function with your wallet address and copy-paste the below URI  <br /><a href='https://metadrip.xrcouture.com/json/WRBLTester.json' target='_blank'>https://metadrip.xrcouture.com/json/WRBLTester.json</a>
                    </div>
                    <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Docs/image2.png" width="70%" style={{marginLeft:"5%"}} />
                    <div className='font-body mt-2'>
                    The NFT is free and you have to pay only the gas fees.
                    </div>
                    </li>
                    </ol>

                    </li>
                    <li>
                    <div className='font-body mt-2'>
                    Use the below REST API to retrieve the 3d files
                    </div>
                    <div className='font-body mt-2' style={{wordBreak:"break-all"}}><a href="https://api.model.metadrip.xrcouture.com" target='_blank'><span className='link-text' style={{float:"left",padding:"2px 3px",marginRight:"3px", background:"#3578e5",color:"white",borderRadius:"3px"}}>GET</span>{" https://api.model.metadrip.xrcouture.com/web3/v1/{apikey}/platform/{platformName}/address/{walletAddress}"}
                    </a></div>
                    <div className='font-body mt-2'>
                    You need an unique key to access this API. <a href='mailto:hello@xrcouture.com?subject=Request%20API%20Key'>Mail us</a> to get an API key. 
                    </div>
                    </li>
                    </ol>
                    <div className='text-sub-heading mt-4'>Web2 API</div> 
                    <ol type='1' style={{marginLeft:"25px"}}>
                    <li >
                    <div className='font-body mt-2 '>
                    Share your email Address <a className='text-primary' style={{cursor:"pointer"}} onClick={()=>setModal(true)}>here</a>
                    </div>

                    </li>
                    <li>
                    <div className='font-body mt-2'>
                    Use the below REST API to retrieve the 3d files
                    </div>
                    <div className='font-body mt-2' style={{wordBreak:"break-all"}}><a href="https://api.model.metadrip.xrcouture.com" target='_blank'><span className='link-text' style={{float:"left",padding:"2px 3px",marginRight:"3px", background:"#3578e5",color:"white",borderRadius:"3px"}}>GET</span>{" https://api.model.metadrip.xrcouture.com/web2/v1/{apikey}/platform/{platformName}/email/{emailAddress}/"}
                    </a></div>
                    <div className='font-body mt-2'>
                    You need an unique key to access this API. <a href='mailto:hello@xrcouture.com?subject=Request%20API%20Key'>Mail us</a> to get an API key. 
                    </div>
                    </li>
                    </ol>
            </div>

        {/* naivation */}
        <div className='static-navigation d-flex align-items-center row justify-content-around flex-row m-0' style={{bottom:"0px",width:"100%",paddingTop:"20px",paddingBottom:"20px"}} >

        <div className='prev-navigation flex-row col-15 col-md-5 justify-content-between ' style={{cursor:"pointer"}} onClick={()=>navigate('/api-overview')}>
        <div>
          <p className='m-0 text-white font-body' >Previous</p>
          <h6 className='text-primary font-body'>&lt;&lt; 📄 API Overview</h6>   
        </div>
        
        </div>
        <div className='next-navigation justify-content-between col-15 col-md-5 ' style={{cursor:"pointer"}} onClick={()=>navigate('/collections')}>
        <div  style={{cursor:"pointer"}}>
          <p className='m-0 text-white font-body' >Next</p>
          <h6 className='text-primary font-body'>👩‍🦳 Collections &gt;&gt;</h6>  
        </div>
        </div>
</div>   

      <div className='support-container font-body'>
        <div className='link-text text-center'>Need help or have questions? Contact us at <a href="mailto:hello@xrcouture.com">hello@xrcouture.com</a> and we’ll get back to you as soon as possible.</div>
      </div>

        </main>


        <Modal isOpen={modal}  toggle={()=>setModal(false)} data-bs-theme="dark">
        <ModalHeader toggle={()=>setModal(false)}>Web2 API</ModalHeader>
        <ModalBody>
        <form onSubmit={async e=>{
           setLoading(true)
           e.preventDefault()
           if(email !==""){
             // api.model.metadrip.xrcouture.com
             await axios.post(`https://api.model.metadrip.xrcouture.com/platform/mapweb2asset`,{email:email},{
               withCredentials:true
             }).then(res=>{
               toast.success("Email submitted succesfully..!")
               console.log(res)
               setModal(false)
             }).catch((error) => {
               if (error.response) {
                 // The request was made and the server responded with an error status code
                 console.log('Server error:', error.response.data.msg);
                 toast.error(`Oops, ${error.response.data.msg}`)
               } else if (error.request) {
                 // The request was made but no response was received
                 console.log('Client error:', error.message);
                 toast.error(`Oops, ${error.message}`);
               } else {
                 // Something else happened while setting up the request
                 console.log('Error:', error.message);
                 toast.error(`Oops, ${error.message}`);
       
               }
               setLoading(false)
             });
           }
        }}>
        <div class="input-group mb-3">
          <input type="email" class="form-control" required placeholder="Enter your email id" value={email} onChange={(e)=>setEmail(e.target.value)} aria-label="Enter your email id" aria-describedby="basic-addon2" />
          <div class="input-group-append">
            <button class="btn btn-primary" style={{height:"100%"}} type="submit" disabled={loading}>
              {loading ?<ThreeDots 
              width="2em"
              height="max-content"
color="#fff" 
ariaLabel="three-dots-loading"
wrapperStyle={{}}
wrapperClassName=""
visible={true}
 /> : "Submit"}
            </button>
          </div>
        </div>
        </form>
        </ModalBody>
      </Modal>


      <Toaster
                position="top-right"
                reverseOrder={false} />


        {/* submenu */}

       {mob && <div style={{width:"15%", height:"100%", position:"fixed", right:0}}>
          <div className='submenu-container link-text'>
            <a href='#rest-api' className="my-link">Rest API</a>
            <a href='#rest-api-web3' className="my-link">Rest API (Web3)</a>
            <a href='#rest-api-web2' className="my-link">Rest API(Web2)</a>
            <a href='#test-api' className="my-link">How to test API</a>
          </div>
        </div>}
    </div>
  )
}

export default RestAPI