import './App.css';
import GettingStarted from './Components/GettingStarted/GettingStarted';
import Overview from './Components/Overview/Overview';
import RestAPI from './Components/RestAPI/RestApi';
import Sidebar from './Components/Sidebar/Sidebar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RouteNotDefined from './RouteNotDefined';
import Collections from './Components/Collections/Collections';



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' exact element={<Sidebar><GettingStarted /></Sidebar>} />
          <Route path="/api-overview" element={<Sidebar><Overview /></Sidebar>} />
          <Route path="/api" element={<Sidebar><RestAPI /></Sidebar>} />
          <Route path='/collections' element={<Sidebar><Collections/> </Sidebar>} />
        </Routes>

        <Routes element={<RouteNotDefined />}>
              <Route path="*" />
            </Routes>

      </Router>



    </>
  );
}

export default App;
